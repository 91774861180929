<template>
  <div class="container-info">
    <div class="">
      <div class="social">
        <div class="msg">
          <h3>Seja Bem vindo(a)</h3>
        </div>
        <div class="infoMenu">
          <div class="profile">
            <p>[ {{ resource.profile }} ]</p>
          </div>
          <div class="nome">
            <p>{{ resource.fullName }}</p>
          </div>
        </div>
      </div>
      <div class="">
        <div class="">
          <v-btn depressed rounded text @click="logout">
            <v-icon size="22" class="icon">mdi-power-standby</v-icon>
          </v-btn>
          <v-btn depressed rounded text>
            <router-link to="/dashboard" class="link2">
              <v-icon size="22" class="icon">mdi-home</v-icon>
            </router-link>
          </v-btn>
          <v-btn depressed rounded text @click="alterPassword.dialogPass = true">
            <v-icon size="22" class="icon">{{ iconkey }}</v-icon>
          </v-btn>
          <newPass v-show="false" :resource="alterPassword" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newPass from '../login/NewPassword-comp.vue'
export default {
  data() {
    return {
      alterPassword: {
        dialogPass: false,
        btnLogged: "ALTERAR SENHA",
      },
      iconkey: "mdi-key-variant",
      currentDate: new Date().toLocaleDateString(),
    };
  },

  props: {
    resource: {
      type: Object,
    },
  },

  components: {
    newPass
  },

  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/");
      this.$store.state.authCode = '';
    },
  },
};
</script>

<style>
.container-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #35353b;
  color: #a19f9f;
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info {
  display: flex;
  flex-direction: column;
  background: #fff;
  color: #a19f9f;
  border-radius: 8px 8px 8px 8px;
  width: 210px;
}

.social {
  font-size: 20px;
  font-weight: 600;
}

.infoMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.profile {
  font-size: 10px;
  font-weight: 400;
  color: #f44949;
  margin-right: 5px;
}

.nome {
  font-size: 15px;
  font-weight: 400;
}

.link2 {
  text-decoration: none;
  color: dark;
  display: flex;
  align-items: center;
  transition: 0.8s;
}

.icon {
  margin-right: 5px;
  color: #a19f9f !important;
}

.box-name>small {
  font-size: 11px;
}

.box-name>h5 {
  font-size: 14px;
}
</style>
