<template>
  <v-navigation-drawer app class="sidebar" v-model="drawer">
    <div class="box-sidebar-logo sisnefro-sidebar">
      <h1 class="text-logo">SCSEB</h1>
      <h6 class="text-system-name">
        SISTEMA DE CONFERÊNCIAS DE SAÚDE DO ESTADO DA BAHIA
      </h6>
      <div id="example"></div>
    </div>
    <UserLeft :resource="authUser" />
    <div class="card-menu" width="300">
      <v-list>
        <h6 class="text-section-menu">MENU PRINCIPAL</h6>
        <v-list-item-group class="list-group">
          <router-link to="/usuarios" class="link" v-show="show_users">
            <span>Usuários</span>
          </router-link>
          <router-link to="/dashboard/conference" class="link">
            <span>Conferências</span>
          </router-link>
          <v-list-group class="list-group">
            <template v-slot:activator class="link">
              <v-list-item-group class="links">
                <span>Cadastros Administrativos</span>
              </v-list-item-group>
            </template>
            <div class="card-list">
              <router-link
                to="/macro-regiao"
                class="link"
                v-show="show_regional_nucleus_health"
              >
                <span>Macrorregião</span>
              </router-link>
              <router-link
                to="/regiao-saude"
                class="link"
                v-show="show_health_region"
              >
                <span>Região de Saúde</span>
              </router-link>
              <router-link
                to="/municipios"
                v-show="show_regional_nucleus_health"
                class="link"
              >
                <span>Municípios</span>
              </router-link>
              <router-link to="/orgao" class="link" v-show="show_agency">
                <span>Orgão/Entidade</span>
              </router-link>
              <router-link
                to="/instituicao"
                v-show="show_institutes"
                class="link"
              >
                <span>Instituição</span>
              </router-link>
              <router-link to="/people" v-show="show_people" class="link">
                <span>Lista de Participantes</span>
              </router-link>
            </div>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </div>
    <span class="mt-10 ml-3" style="color: #a19f9f; margin-bottom: -30px">
      <em>Versão {{ version }} </em>
    </span>
  </v-navigation-drawer>
</template>

<script>
import UserLeft from "../navbar/MenuNavbar-comp-left.vue";
import axios from "@/axios";
import config from "../../config/config";

export default {
  data() {
    return {
      version: config.version,
      drawer: null,
      show_regional_nucleus_health: true,
      show_health_region: true,
      show_searchs_report: true,
      show_users: true,
      show_institutes: true,
      show_people: true,
      show_agency: true,
      authUser: {
        initials: "",
        fullName: "",
        email: "",
        profile_id: "",
        profile: "",
      },

      items: [
        {
          active: true,
          items: [
            { title: "5º Conferência de Saúde Mental" },
            { title: "11º Conferência Estadual de Saúde" },
          ],
          title: "Conferências",
        },
        {
          items: [{ title: "List Item" }],
          title: "Relatórios",
        },
      ],
    };
  },

  created() {
    
    axios.get('/conference/current').then((response) => {
      this.$store.state.conference = response.data;
    });

    axios.get(`/auth/me`).then((response) => {      
      this.authUser.initials = response.data.name.substr(0, 1);
      this.authUser.fullName = response.data.name.split(" ")[0];
      this.authUser.email = response.data.email;
      this.authUser.profile = response.data.profile;
      this.authUser.profile_id = response.data.profile_id;
      this.authUser.county_id = response.data.county_id;
      this.$store.state.profile = response.data.profile;
      this.$store.state.profile_id = response.data.profile_id;
      this.$store.state.county_id = response.data.county_id;
      this.$store.state.institute_id = response.data.institute_id;
      this.$store.state.regional_nucleus_health_id =
        response.data.regional_nucleus_health_id;
      this.$store.state.state_id = response.data.state_id;
      this.$store.state.finished_release_conference =
        response.data.finished_release_conference;
      this.$store.state.restrict_release_conference =
        response.data.restrict_release_conference;
      if (response.data.profile_id == 1 || response.data.profile_id == 2) {
        this.show_people = true;        
      }
      if (response.data.profile_id == 2) {
        this.show_regional_nucleus_health = false;
        this.show_health_region = false;
        this.show_searchs_report = false;
        return;
      }

      if (response.data.profile == "RELATORIA") {
        this.show_users = false;
        this.show_regional_nucleus_health = false;
        this.show_health_region = false;
        this.show_searchs_report = false;
        this.show_institutes = false;
        this.show_people = false;
        this.show_agency = false;
        return;
      }

      if (response.data.profile_id != 2 && response.data.profile_id != 1) {
        this.show_users = false;
        this.show_regional_nucleus_health = false;
        this.show_health_region = false;
        this.show_searchs_report = false;
        this.show_institutes = false;
        this.show_people = false;
        this.show_agency = false;
        return;
      }

      
    });
  },

  props: {
    resource: {
      type: Boolean,
    },
  },

  components: {
    UserLeft,
  },

  watch: {
    resource: function () {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style>
.v-navigation-drawer__border {
  width: 0px !important;
  transition: 0.8s;
}

.sidebar {
  background: #35353b !important;
  transition: 0.8s;
}

.sisnefro-sidebar {
  background-color: #626262;
  width: 100%;
  height: 95px;
  transition: 0.8s;
}

.box-sidebar-logo {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 10px;
}

.text-logo {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #fdfdfd;
}

.text-system-name {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #cac6c6;
}

.card-menu {
  padding: 10px;
  background: #2c2c31;
  color: #a19f9f;
}

.card-list {
  padding: 10px;
  background: #2c2c31;
}

.text-section-menu {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #a19f9f;
  margin-bottom: 5px;
}

.list-group {
  display: flex;
  flex-direction: column;
}

.template {
  padding: 10px;
  margin: 5px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
  background: #a4a5a7;
}

.link {
  padding: 10px;
  margin: 5px 7px;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
}

.buttonConf {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #a19f9f;
  margin-left: 16px !important;
}

.icon-conf {
  margin-right: 5px;
}

.links {
  padding: 0px;
  margin: 0px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
}

.link:hover {
  background: #35353b;
  transition: 0.8s;
  color: #a19f9f;
}

.links:hover {
  background: #35353b;
  transition: 0.8s;
  color: #a19f9f;
}

.link > .icon {
  margin-right: 5px;
  color: #a19f9f !important;
}

.links > .icon {
  margin-right: 0px;
  color: #a19f9f !important;
}

.link > span {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #a19f9f;
}

.links > span {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #a19f9f;
}

.link-ativo {
  background: #35353b;
  color: red !important;
}

.links-ativo {
  background: #35353b;

  color: rgb(8, 1, 1);
}

/* Mobile */
.v-navigation-drawer--is-mobile:not(.v-navigation-drawer--close) {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
  background: #35353b !important;
}
</style>
