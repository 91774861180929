<template>
  <div>
    <Sidebar :resource="drawer" />
    <v-app-bar color="#FFFFFF" app class="navbar" elevation="0">
      <div class="app-itens">
        <div class="sidebar-action">
          <div class="header-sidebar">
            <div>
              <v-btn @click="clicando" icon elevation="0" large>
                <v-icon color="#000">{{ menuIcon }}</v-icon>
              </v-btn>
              <span>{{ this.$router.history.current.name }}</span>
            </div>
          </div>
          |
          <span class="pageIndicator">{{ this.$router.history.current.name }}</span>
        </div>
        <div class="sidebar-action-rioght">
          <router-link to="/dashboard" class="linker">
            <span>Início</span>
          </router-link>
          <span v-if="$route.meta.fromName">></span>
          <router-link v-if="$route.meta.fromName" :to="$route.meta.fromPath" class="linker">
            <span>{{ $route.meta.fromName }}</span>
          </router-link>
          <span>></span>

          <router-link class="linker" :to="$route.path">
            <span>{{ $route.name }}</span>
          </router-link>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import Sidebar from "../sidebar/Sidebar-comp.vue";
import axios from "@/axios";

export default {
  data() {
    return {
      drawer: null,
      menuIcon: "mdi-format-list-bulleted",
      currentRouteName: "",
      routeNameDetails: {
        to: "",
        from: "",
      },
      authUser: {
        initials: "",
        fullName: "",
        email: "",
      },
      router: "",
    };
  },

  components: {
    Sidebar,
  },

  created() {
    axios.get(`/auth/me`).then((response) => {
      this.authUser.initials = response.data.name.substr(0, 1);
      this.authUser.fullName = response.data.name.split(" ")[0];
      this.authUser.email = response.data.email;
    });
  },

  watch: {
    $route() {
      this.currentRouteName = this.$router.history.current.name;
    },
    deep: true,
  },

  methods: {
    routeFrom(){
     return this.$route.meta
    },
    clicando() {
      this.drawer = !this.drawer;
    },
  },
  computed: {

  },
};
</script>

<style>
.navbar {
  position: absolute !important;
  height: 91px !important;
  -webkit-box-shadow: -2px 6px 15px 7px rgba(0, 0, 0, 0.51);
  box-shadow: -2px 6px 15px 7px rgba(0, 0, 0, 0.51) !important;
}

.sidebar-action-rioght {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin-top: 10px;
}

.sidebar-action-rioght p {
  margin-right: 7px;
}

.v-toolbar__content {
  padding: 0 !important;
}

.header-sidebar {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.header-sidebar > div {
  display: flex;
  align-items: center;
}

.app-itens {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  box-shadow: 1px 4px 12px 3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 1px 4px 12px 3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 1px 4px 12px 3px rgba(0, 0, 0, 0.46);
}

.sidebar-action {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  height: 100%;
}

.linker {
  padding: 10px;
  margin: 5px 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: 0.8s;
  color: #312f2f !important;
}

.linker:hover {
  transition: 0.8s;
  color: #8abbf3 !important;
}

.adjust-home {
  border: 1px solid red;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sidebar-action > .pageIndicator {
  font-size: 16px;
  font-family: "Poppins";
  font-weight: 500;
  margin-left: 10px;
}
</style>
